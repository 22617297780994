<template>
	<div>
		<div
			class="row">
			<div class="col">
				<b-button
					:variant="!showFilters ? 'primary' : 'secondary'"
					class="btn mx-1 bg-primary-alt"
					@click="showFilters = !showFilters">
					<i
						class="fa fa-filter"
						aria-hidden="true" />
				</b-button>
				<div class="btn btn-lg">
					{{ datePeriod }}
				</div>
			</div>
		</div>
		<hr>
		<data-filter
			:display="showFilters"
			get-by="name"
			@submit="getDataFiltered"
			@clear="clearFilters({ action: '' })">
			<template v-slot:form>
				<div class="row">
					<div
						class="col-md-4 col-sm-4 col-12">
						<search
							v-model="filters.username"
							:label-text="translate('autocomplete_label')"
							:endpoint="endpoint"
							:empty-result-message="translate('data_not_found')"
							:placeholder="'autocomplete_placeholder'"
							:default-params="{role:'admin,corporate'}"
							class="mb-3"
							q="label"
							return-key="username" />
					</div>
					<div
						class="col-md-4 col-sm-4 col-12">
						<div
							class="form-group">
							<label
								for="action"
								class="label">{{ translate('action') }}</label>
							<select
								id="action"
								v-model="filters.action"
								name="action"
								class="form-control">
								<option :value="''">
									{{ translate('all') }}
								</option>
								<option
									v-for="action in actions"
									:key="action"
									:value="action">
									{{ translate(action) }}
								</option>
							</select>
						</div>
					</div>
					<div
						class="col-md-4 col-sm-4 col-12">
						<div
							class="form-group">
							<label
								for="dateRange"
								class="label">{{ translate('date_range') }}</label>
							<select
								id="dateRange"
								v-model="dateRange"
								name="dateRange"
								class="form-control">
								<option
									:value=" '' ">
									{{ translate('all') }}
								</option>
								<option
									v-for="dateRange in dateRanges"
									:key="dateRange"
									:value="dateRange">
									{{ translate(dateRange) }}
								</option>
							</select>
						</div>
					</div>
				</div>
			</template>
		</data-filter>
		<inventory-changes-table
			:has-data="hasData"
			:loading="loading"
			:changes="changes"
			@currentDetail="currentDetail = $event" />
		<is-loading
			:loading-label="translate('loading')"
			:no-data-label="translate('data_not_found')"
			:loading="loading"
			:has-data="hasData" />
		<b-pagination
			v-if="pagination.total_pages > 1"
			v-model="pagination.current_page"
			:total-rows="pagination.total"
			:per-page="pagination.per_page"
			align="center"
			@change="getDataFiltered" />
		<inventory-changes-detail :id="currentDetail" />
	</div>
</template>

<script>
import Inventory from '@/util/Inventory';
import { DATE_RANGES as dateRanges, MDY_FORMAT as mdyFormat, YMDHMS_FORMAT } from '@/settings/Dates';
import { Grids, InventoryChanges } from '@/translations';
import DataFilter from '@/components/DataFilter/index.vue';
import { SEARCH_USERS } from '@/config/endpoint';
import { PAGINATION } from '@/settings/RequestReply';
import FiltersParamsEnhanced from '@/mixins/FiltersParamsEnhanced';
import InventoryChangesDetail from '@/views/Inventory/Logs/InventoryChanges/InventoryChangesDetail.vue';
import InventoryChangesTable from '@/views/Inventory/Logs/InventoryChanges/InventoryChangesTable.vue';

const { endpoint } = SEARCH_USERS;

export default {
	name: 'InventoryChanges',
	components: { InventoryChangesTable, InventoryChangesDetail, DataFilter },
	messages: [Grids, InventoryChanges],
	mixins: [FiltersParamsEnhanced],
	data() {
		return {
			inventory: new Inventory(),
			dateFormat: YMDHMS_FORMAT,
			dateRanges,
			endpoint,
			actions: ['update_stock', 'update_stock_status', 'transfer_stock'],
			currentDetail: '',
		};
	},
	computed: {
		changes() {
			try {
				return this.inventory.data2.response.data.data;
			} catch (error) {
				return [];
			}
		},
		loading() {
			try {
				return !!this.inventory.data2.loading;
			} catch (error) {
				return false;
			}
		},
		hasData() {
			return this.changes.length > 0;
		},
		pagination() {
			return this.inventory.data2.pagination;
		},
		rangeStart() {
			try {
				if (this.language) { // If the language changes, the date will be recalculated and displayed in the new language :)
					return this.$moment(this.inventory.data2.response.data.meta.period_dates.start_date.date).format(mdyFormat);
				}
			} catch (error) {
				// do nothing
			}
			return '';
		},
		rangeEnd() {
			try {
				if (this.language) { // If the language changes, the date will be recalculated and displayed in the new language :)
					return this.$moment(this.inventory.data2.response.data.meta.period_dates.end_date.date).format(mdyFormat);
				}
			} catch (error) {
				// do nothing
			}
			return '';
		},
		datePeriod() {
			const start = this.rangeStart;
			const end = this.rangeEnd;
			if (start !== '' && end !== '') {
				return this.translate('date_period', { start, end });
			}

			if (start !== '') {
				return this.translate('date_period_from', { start });
			}

			if (end !== '') {
				return this.translate('date_period_until', { end });
			}
			return '';
		},
	},
	watch: {
		'$route.query': {
			handler() {
				this.getDataFiltered();
			},
			deep: true,
		},
	},
	mounted() {
		if (typeof this.filters.action === 'undefined') {
			this.filters.action = '';
		}
		this.getDataFiltered();
	},
	methods: {
		getDataFiltered(page) {
			if (typeof page === 'object') {
				this.filters = { ...this.filters, ...page };
			}
			const { query } = this.$route;
			const options = { ...query, ...this.filters };
			if (typeof page === 'number') {
				options[PAGINATION] = page;
			}

			this.inventory.changes(options);
		},
	},
};
</script>
